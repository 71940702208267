<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.contact_support') }}</h1>
      </header>

      <div>
        <p>
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ $t('message.message_received') }}
          </v-alert>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'Contact',
  created () {
    this.$emit('update:layout', DefaultLayout)

    document.title = config.title + ' - Contact validation'
  }
}
</script>
